<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title color="primary">
        <h4 color="primary">
          {{$t('message.item-recomend-title')}}
        </h4>
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <!-- routering to create item -->
      <template>
        <router-link to="/editRecomend">
          <v-btn rounded color="primary">
            <v-icon left>
              mdi-plus
            </v-icon>
            {{$t('message.update-recomend')}}
          </v-btn>
        </router-link>
      </template>
    </v-toolbar>

    <FilterItemRecomend />
    <!-- data table -->
    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
    ></v-pagination>

    <v-data-table
      :headers="headers"
      :items="itemList"
      disable-sort
      class="elavation-1"
      :server-items-length="itemTotal"
      :options.sync="option"
      :page="currentPage"
      :items-per-page="itemPerPage"
      :footer-props="{'items-per-page-options':[itemPerPage]}"
      :hide-default-footer="true"
      :hide-default-header="true"
      :loading="isLoading"
    >

    <template v-slot:header="{ props }" style="min-height: 50px">
        <th class="custom-th text-center">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" style="width: 25%;">
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[5].text }}
        </th>
        <th class="custom-th text-center" style="width: 15%">
          {{ props.headers[6].text }}
        </th>
      </template>

      <!-- imageproduct itemlist loop -->
      <template v-slot:[`item.image_url`]="{ item }">
        <v-img
          :lazy-src="item.image_url ? item.image_url : notFound"
          :src="item.image_url ? item.image_url : notFound"
          class="image-url-class"
          style="width:100px; height: auto;"
        ></v-img>
      </template>

      <!-- action button itemlist loop -->
      <template v-slot:[`item.actions`]="">
        <v-btn :color="'success'" style="font-size: 10px">
          <v-icon left>
            mdi-thumb-up
          </v-icon>
          {{$t('message.recomended')}}
        </v-btn>
        <!-- <v-icon color="indigo" small class="mr-2" @click="detailItem(item.id)">
          mdi-eye
        </v-icon> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FilterItemRecomend from "@/components/FilterItemRecomend";
import moneyFormat from '../helpers/moneyFormat';

export default {
  name: "ItemRecomend",
  components: {
    FilterItemRecomend
  },
  data: () => ({
    notFound: require('../assets/image-not-found.png'),
    dialogDelete: false,
    deletedId: 0,
    option: {},
    isLoading: false,
    page: 1,
    
  }),

  watch: {
    dialogDelete (val) {
      val || this.cancelDelete()
    },
    // option: {
    //   handler() {
    //     this.fetchItem();
    //   },
    //   deep: true,
    // },
  },
  

  methods: {
    
    fetchItem() {
      // const {page} = this.option
      this.$store.dispatch('itemRecomend_module/fetchItemRecomendList', {'page': this.page})
    },
    deleteRecomendItem(item) {
      this.isLoading = true
      this.deletedId = item.item_recom_id
      this.dialogDelete = true
    },
    cancelDelete() {
      this.dialogDelete = false
      this.isLoading = false
    },
    async deleteItemConfirm() {
      let resp = await this.$store.dispatch('itemRecomend_module/deleteItemRecomend', {
        'recomend_id': this.deletedId
        
      })
      this.dialogDelete = false
      this.isLoading = false
      this.fetchItem()
    },
    changePage(event) {
      return this.$store.dispatch("itemRecomend_module/fetchItemRecomendList", {
        page: event,
      });
    },
  },
  created() {
    this.fetchItem();
  },
  mounted(){
    this.$store.dispatch('itemRecomend_module/clearFilter')
  },
  computed: {
    isLength() {
      return this.$store.state.itemRecomend_module.is_length;
    },
    lengthPage() {
      return this.$store.state.itemRecomend_module.length_page;
    },
    itemList() {
      let itemData = this.$store.state.itemRecomend_module.itemRecomendList
      if (!itemData) return 0
      else itemData.map(el => {
        el.price = moneyFormat(el.price)
      })
      return itemData
    },
    itemTotal() {
      let resp = this.$store.state.itemRecomend_module.itemRecomendHeader
      if(resp.total === undefined) {
        return 0
      }
      return resp.total
    },
    itemPerPage() {
      let resp = this.$store.state.itemRecomend_module.itemRecomendHeader
      if(resp.per_page === undefined) {
        return 1
      }
      return resp.per_page
    },
    currentPage() {
      let resp = this.$store.state.itemRecomend_module.itemRecomendHeader
      if(resp.current_page === undefined) {
        return 1
      }
      return resp.current_page
    },
    headers() {
      return [
        {
          text: this.$t('message.id'),
          align: "start",
          value: "id",
        },
        { text: this.$t('message.shop'), value: "from_store" },
        { text: this.$t('message.image'), value: "image_url" },
        { text: this.$t('message.name'), value: "item_name", width: "300px" },
        { text: this.$t('message.stock'), value: "stock" },
        { text: this.$t('message.price'), value: "price" },
        // { text: this.$t('message.status'), value: "status" },
        { text: this.$t('message.action'), value: "actions" }
      ]
    }
  },

};
</script>

<style>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
.image-url-class {
  margin: 10px auto !important
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-application .text-start,
tr td {
  text-align: center !important;
  padding-left: 0px !important;
  padding-right: 0px !important;  
}
.v-data-footer-top {
  border-top: none !important;
}
</style>
