<template>
  <v-container>
    <v-row no-gutters align="center" justify="space-between">
      <v-col cols="10">
        <v-row>
          <v-col cols="12" md="3" xs="6">
            <v-label class="p-0 m-0">{{ $t("message.item-id") }}</v-label>
            <v-text-field
              v-model="itemId"
              class="p-0 mt-1"
              clearable
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" xs="6">
            <v-label class="p-0 m-0">{{ $t("message.shop") }}</v-label>
            <v-select
              v-model="shopId"
              :items="storeListData"
              item-text="store_name"
              item-value="id"
              class="p-0 mt-1"
              clearable
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="3" xs="6">
            <v-label class="p-0 m-0">{{ $t("message.name") }}</v-label>
            <v-text-field
              v-model="name"
              class="p-0 mt-1"
              clearable
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3" xs="4">
            <span class="pt-3" style="color: grey">{{$t("message.label-price-range")}}</span>
            <v-row no-gutters>
              <v-col>
                <vuetify-money
                  class="mt-1"
                  v-model="priceStart"
                  outlined
                  clearable
                  v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                  v-bind:options="options"
                />
              </v-col>
              <v-col class="price-tilde">
                <v-icon class="tilde-class py-2 px-2">mdi-tilde</v-icon>
              </v-col>
              <v-col>
                <vuetify-money
                  class="mt-1"
                  v-model="priceEnd"
                  outlined
                  clearable
                  v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                  v-bind:options="options"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3" xs="6">
            <v-label class="p-0 m-0">{{ $t("message.status") }}</v-label>
            <v-select
              class="p-0 mt-1"
              outlined
              clearable
              v-model="selectStatus"
              :items="statusItems"
              item-text="text"
              item-value="id"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" md="3" xs="6">
            <v-label class="p-0 m-0">{{ $t("message.category") }}</v-label>
            <v-select
              class="p-0 mt-1"
              outlined
              clearable
              v-model="categoryId"
              :items="categoryList"
              item-text="category_name"
              item-value="id"
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pr-2 pb-5 ps-8" cols="6" md="1" xs="6">
        <v-btn style="" @click="submitFilterItem" rounded color="primary">
          <v-icon left>
            {{ iconSearch }}
          </v-icon>
          {{ $t("message.search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "FilterItemRecomend",
  data: () => ({
    iconSearch: mdiMagnify,
    selectStatus: {id: "", text: ""},
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    storeList: [],
    name: "",
    tempStore: [],
    shopId: "",
    itemId: "",
    name: "",
    priceStart: "",
    priceEnd: "",
    categoryId: ""
  }),
  computed: {
    categoryList() {
      return this.$store.state.itemList_module.categoryList;
    },
    storeListData() {
      return this.$store.state.shop_module.shopAllResult;
    },
    statusItems() {
      return [
        { id: "1", text: this.$t('message.active') },
        { id: "0", text: this.$t('message.inactive') }
      ]
    }
  },
  created() {
    this.dispatchStoreList();
    this.dispatchCategoryList()
    this.initFilterRecomend()
  },
  methods: {
    dispatchStoreList() {
      this.$store.dispatch("shop_module/fetchShopAll");
    },
    dispatchCategoryList() {
      this.$store.dispatch("itemList_module/categoryList");
    },
    submitFilterItem(){
      this.$store.dispatch('itemRecomend_module/fetchItemRecomendList', {
        'shopId': this.shopId === null ? '' : this.shopId,
        'itemId': this.itemId === null ? '' : this.itemId,
        'name': this.name === null ? '' : this.name,
        'categoryId': this.categoryId === null ? '' : this.categoryId,
        'priceStart': this.priceStart === null ? '' : this.priceStart,
        'priceEnd': this.priceEnd === null ? '' : this.priceEnd,
        'selectedStatus': this.selectStatus === null ? '' : this.selectStatus,
        page: 1
      })
    },
    initFilterRecomend() {
      this.shopId = this.$store.state.itemRecomend_module.filter.shopId;
      this.itemId = this.$store.state.itemRecomend_module.filter.itemId
      this.name = this.$store.state.itemRecomend_module.filter.name;
      this.categoryId = this.$store.state.itemRecomend_module.filter.categoryId;
      this.priceStart = this.$store.state.itemRecomend_module.filter.priceStart;
      this.priceEnd = this.$store.state.itemRecomend_module.filter.priceEnd;
      this.selectStatus = this.$store.state.itemRecomend_module.filter.selectedStatus
    }
  },
};
</script>

<style>
.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.font-size {
  font-size: 12px !important;
}
.btn-search {
  border-radius: 20px;
}
.v-input .v-input__control .v-text-field__details {
  /* display: none; */
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}


.price-tilde {
  max-width: fit-content;
  /* max-width: 20%; */
}
.price-tilde,
.tilde-class {
  font-size: 15px;
  margin-bottom: 15px;
  /* margin-left: 3px; */
  /* margin-right: 3px; */
  /* margin-top: 8px; */
}
</style>
